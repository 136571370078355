@charset "UTF-8";
#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
}

@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 70px 0 0 250px;
        padding: 0 30px 30px;
    }
}

.navbar {
    border: 0;
    font-weight: 500;
    min-height: 70px;
    margin-bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    font-family: 'Raleway', sans-serif;
    background-color: #fff;
    border-color: #fff;
}

@media (max-width: 767px){
    .navbar-fixed-bottom, .navbar-fixed-top {
        position: relative;
    }

}

@media (min-width: 768px) {
    .nav-container {
        padding: 0 1rem;
        height: 70px;
        background: inherit;
        margin-left: 250px;
        position: relative;
    }
}

.navbar-nav > li > a {
    letter-spacing: 0.5px;
}

@media (min-width: 768px) {
    .navbar-nav > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.navbar-nav > li > a {
    color: #374767;
}


/*dropdown menu*/

.dropdown-menu {
    border: 0;
    border-radius: 0px;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
    -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
}

.dropdown-menu .divider {
    margin: 0;
    background-color: #e7ecf5;
}

.navbar-nav > li > .dropdown-menu {
    margin: 20px 0px 0;
}

.dropdown-menu li a {
    padding: 8px 10px;
    min-height: 0;
    color: #374767;
    font-weight: 500;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    border: 0;
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 0;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
    -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
}

.dropdown-submenu > a::before {
    font-family: FontAwesome;
    float: right;
    content: "\f105";
    margin-top: 0;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #555;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}


/*mega menu*/

.mega-dropdown {
    position: static !important;
}

.mega-dropdown-menu {
    padding: 0;
    margin-left: 30px!important;
    margin-right: 30px!important;
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
}

.navbar-nav > li > .dropdown-menu:before,
.navbar-top-links .dropdown-menu:before {
    content: "";
    border-bottom: 9px solid #fff;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    position: absolute;
    top: -9px;
    left: 220px;
    z-index: 10;
}

.navbar-nav > li > .dropdown-menu:after,
.navbar-top-links .dropdown-menu:after {
    content: "";
    border-bottom: 10px solid rgba(39, 44, 48, 0.16);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -10px;
    left: 219px;
    z-index: 8;
}

.navbar-top-links .dropdown-user:before {
    left: 131px;
}

.navbar-top-links .dropdown-user:after {
    left: 130px;
}

.navbar-nav > li > .dropdown-menu:before {
    left: 20px;
}

.navbar-nav > li > .dropdown-menu:after {
    left: 19px;
}

.navbar-nav > li > .dropdown-menu.mega-dropdown-menu:before {
    left: 294px;
}

.navbar-nav > li > .dropdown-menu.mega-dropdown-menu::after {
    left: 293px;
}

.mega-dropdown-menu li {
    margin: 0;
}

.mega-dropdown-menu li .col-menu {
    padding: 0;
    border-right: solid 1px #e7ecf5;
    width: 20%;
    margin: 0;
}

.mega-dropdown-menu li .col-menu:last-child {
    border-right: 0;
}

.mega-dropdown-menu li .col-menu ul.menu-col {
    padding: 0;
    margin: 0;
    list-style: none;
}

.mega-dropdown-menu li .col-menu ul.menu-col li {
    border-top: solid 1px #e7ecf5;
    position: relative;
}

.mega-dropdown-menu li .col-menu ul.menu-col li:first-child {
    border-top: 0;
}

.mega-dropdown-menu li .col-menu ul.menu-col li a {
    position: relative;
    color: #374767;
    display: block;
    text-decoration: none;
    padding: 10px 15px 10px 20px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

.mega-dropdown-menu li .col-menu ul.menu-col li a i {
    margin-right: 10px;
}


/*navber header*/

.navbar-brand {
    height: 71px;
    padding: 10px 15px;
    line-height: 50px;
    font-family: 'Alegreya Sans', sans-serif;
    text-align: center;
    display: block;
    float: none;
}

@media (min-width: 768px) {
    .navbar-header {
        height: 100%;
        padding: 0 15px;
        width: 250px;
        min-width: 250px;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .navbar-brand {
        font-weight: 700;
        font-size: 26px;
    }
    .navbar-brand > img {
        height: 45px;
    }
}

@media (max-width: 767px) {
    .navbar-brand {
        height: 60px;
        padding: 8px 0;
        margin-left: 15px;
    }
    .navbar-brand > img {
        height: 40px;
    }
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 10px;
}


.nav > li > a.btn-buy {
    border-radius: 2em;
    padding: 7px 14px;
    margin: 16px 15px;
    font-size: 13px;
    font-weight: 600;
}

.navbar-top-links li a.dropdown-toggle,
.navbar-nav li a.menu-toggle,
.navbar-nav li a.search-trigger,
.navbar-nav li a#fullscreen,
.navbar-nav li a#menu-toggle,
.navbar-top-links > li.log_out a {
    padding: 22px 10px;
    font-size: 25px;
    line-height: 20px;
}

.navbar-top-links li a.dropdown-toggle:hover,
.navbar-top-links li a.dropdown-toggle:active,
.navbar-top-links li a.dropdown-toggle:focus,
.navbar-nav li a.menu-toggle:hover,
.navbar-nav li a.menu-toggle:active,
.navbar-nav li a.menu-toggle:focus,
.navbar-nav li a.search-trigger:hover,
.navbar-nav li a.search-trigger:active,
.navbar-nav li a.search-trigger:focus {
    color: #fff;
    background-color: transparent;
}

.navbar-top-links li a .label {
    position: absolute;
    top: 13px;
    right: 0px;
    line-height: 11px;
    padding: 0px;
    border-radius: 50em;
    height: 18px;
    width: 19px;
    font-size: 12px;
}


/*Notify*/

.notify {
    position: relative;
    top: -22px;
    right: -4px;
}

.notify .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #fc4b6c;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #fc4b6c;
    position: absolute;
    right: 6px;
    top: -10px;
}

@-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-menu {
    padding: 0;
    opacity: 0;
    display: block;
    margin: 20px 0px 0;
    visibility: hidden;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    /*-webkit-transform: scale(1, 0);*/
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
}

.navbar-top-links .open > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 250px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 15px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -34px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -82px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
    padding: 5px 0;
}

.sidebar .sidebar-nav.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}

.navbar-top-links .dropdown-menu li.rad-dropmenu-footer,
.navbar-top-links .dropdown-menu li.rad-dropmenu-header {
    display: block !important;
    font-size: 13px;
    margin: 0 !important;
    text-align: center;
    /*background-color: #f1f3f6;*/
}

.rad-dropmenu-header {
    border-bottom: 1px solid #EEEEEE;
}

.rad-dropmenu-footer {
    border-top: 1px solid #EEEEEE;
}

.navbar-top-links .dropdown-menu li.rad-dropmenu-footer>a,
.navbar-top-links .dropdown-menu li.rad-dropmenu-header>a {
    line-height: 12px;
    text-decoration: none;
    font-weight: 600;
    padding: 12px 10px;
}


/*.sidebar .sidebar-search {
    padding: 15px;
}*/

.navbar-top-links li.log_out {
    margin-right: -10px;
}

.navbar-top-links > li.log_out a {
    padding: 22px 20px;
}

@media (max-width: 767px) {
    .navbar-header {
        background-color: #1c2260;
    }
    .navbar-toggle {
        border: 0;
        color: #fff;
        font-size: 28px;
        padding: 6px 10px;
        line-height: 1;
    }
    .navbar-toggle:hover {
        background-color: transparent;
    }
    .navbar-nav {
        margin: 0;
    }
    .navbar-top-links {
        /*float: right;*/
    }
    .navbar-top-links li a.dropdown-toggle,
    .navbar-nav li a.menu-toggle,
    .navbar-nav li a.search-trigger,
    .navbar-nav li a#fullscreen,
    .navbar-nav li a#menu-toggle,
    .navbar-top-links > li.log_out a {
        padding: 18px 10px;
    }
    .navbar-top-links > li.log_out a {
        padding: 18px 18px;
    }
    .navbar-top-links li.log_out {
        margin-right: 0px;
        float: right;
    }
    .navbar-top-links .dropdown-messages::before {
        left: 15px;
    }
    .navbar-top-links .dropdown-messages::after {
        left: 14px;
    }
    .navbar-top-links .dropdown-tasks::before {
        left: 47px;
    }
    .navbar-top-links .dropdown-tasks::after {
        left: 46px;
    }
    .navbar-top-links .dropdown-alerts::before {
        left: 95px;
    }
    .navbar-top-links .dropdown-alerts::after {
        left: 94px;
    }
}


/*Sidebar menu*/

.sidebar ul li {
    display: block;
    border-left: 3px solid transparent;
    transition: 0.3s;
    line-height: 24px;
    font-size: 15px;
}

.sidebar ul li.active,
.sidebar ul li:hover {
    color: #fff;
    border-color: #f91942;
    background-color: rgba(255, 255, 255, 0.09);
}

.sidebar ul li.active,
.sidebar ul li.active:hover {
    border-color: #f91942;
}

.sidebar ul li span.nav-tag {
    background-color: rgba(255, 255, 255, 0.2);
    font-family: "Open Sans";
    font-weight: 600;
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    color: #fff;
    padding: 0;
    padding: 0 7px;
    box-sizing: border-box;
    text-align: center;
    min-width: 20px;
    height: 20px;
    letter-spacing: -0.5px;
    text-align: center;
    border-radius: 50px;
    margin-left: 4px;
}

.sidebar ul li span.nav-tag.green {
    background-color: #64bc36;
}

.sidebar ul li span.nav-tag.yellow {
    background-color: #b9be1c;
}

.sidebar ul li span.nav-tag.red {
    background-color: #ee3535;
}

.sidebar .nav-heading {
    padding: 12px 15px;
    font-size: 13px;
    pointer-events: none;
    cursor: default;
    font-weight: 600;
    text-transform: uppercase;
}

.sidebar ul li a {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
}

.sidebar ul li a i {
    color: #98a7b7;
    font-size: 21px;
    margin-right: 7px;
    vertical-align: middle;
}

.sidebar ul li:nth-child(2) a i {
    color: #84d948;
}

.sidebar ul li:nth-child(3) a i {
    color: #cd6724;
}

.sidebar ul li:nth-child(4) a i {
    color: #f29824;
}

.sidebar ul li:nth-child(5) a i {
    color: #adb7be;
}

.sidebar ul li:nth-child(6) a i {
    color: #b348ae;
}

.sidebar ul li:nth-child(8) a i {
    color: #cd6724;
}

.sidebar ul li:nth-child(9) a i {
    color: #21a788;
}

.sidebar ul li:nth-child(10) a i {
    color: #E5343D;
}

.sidebar ul li:nth-child(11) a i {
    color: #EF6C00;
}

.sidebar ul li:nth-child(12) a i {
    color: #FFB61E;
}

.sidebar ul li:nth-child(14) a i {
    color: #428bca;
}

.sidebar ul li:nth-child(15) a i {
    color: #D4E157;
}

.sidebar ul li:nth-child(16) a i {
    color: #00B8D4;
}

.sidebar ul li:nth-child(17) a i {
    color: #64bc36;
}

.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: none !important;
}

.sidebar .nav-second-level li a {
    padding-left: 46px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.sidebar .nav-second-level li {
    /*padding-left: 4px;*/
    background-color: transparent;
    border: 0;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

@media (min-width: 768px) {
    .sidebar {
        z-index: 1;
        width: 250px;
        position: absolute;
        top: 0;
        left: 0;
        /*padding-top: 71px;*/
        min-height: 100%;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    }
    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: white;
}

.chat {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #999999;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}

.login-panel {
    margin-top: 25%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: transparent;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting:after {
    content: "\f0dc";
    float: right;
    font-family: fontawesome;
    color: rgba(50, 50, 50, 0.5);
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green > .panel-heading {
    border-color: #5cb85c;
    color: white;
    background-color: #5cb85c;
}

.panel-green > a {
    color: #5cb85c;
}

.panel-green > a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red > .panel-heading {
    border-color: #d9534f;
    color: white;
    background-color: #d9534f;
}

.panel-red > a {
    color: #d9534f;
}

.panel-red > a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow > .panel-heading {
    border-color: #f0ad4e;
    color: white;
    background-color: #f0ad4e;
}

.panel-yellow > a {
    color: #f0ad4e;
}

.panel-yellow > a:hover {
    color: #df8a13;
}

.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
}

.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #eeeeee;
}

.timeline > li {
    position: relative;
    margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -15px;
    border-top: 15px solid transparent;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ccc;
}

.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -14px;
    border-top: 14px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    border-left: 14px solid #fff;
}

.timeline > li > .timeline-badge {
    z-index: 100;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    background-color: #999999;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(10%);
        width: -moz-calc(10%);
        width: -webkit-calc(10%);
    }
    ul.timeline > li > .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
    ul.timeline > li > .timeline-panel {
        float: right;
    }
    ul.timeline > li > .timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }
    ul.timeline > li > .timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
}

/* Search Overlay */

.fullscreen-search-overlay #fullscreen-search-wrapper #fullscreen-searchform input[type=submit] {
    position: absolute;
    width: 100px;
    height: 100%;
    background-color: transparent;
    border: 0;
    right: 0;
    top: 0;
}

#fullscreen-searchform:focus {
    outline: none;
    border: none;
}

.fullscreen-search-overlay #fullscreen-search-wrapper #fullscreen-searchform .fullscreen-search-icon {
    font-size: 25px;
    position: absolute;
    right: 20px;
    width: 25px;
    top: 33px;
    color: #1c2260;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.fullscreen-search-icon:hover,
.fullscreen-search-icon:focus {
    border: none;
    outline: none;
    color: #1c2260 !important;
    -webkit-transition: transform ease-out .2s;
    -moz-transition: transform ease-out .2s;
    -ms-transition: transform ease-out .2s;
    -o-transition: transform ease-out .2s;
    transition: transform ease-out .2s;
    transform: rotate(180deg);
}

#fullscreen-searchform {
    position: relative;
    verticle-align: middle;
}

.fullscreen-search-overlay #fullscreen-search-wrapper #fullscreen-searchform #fullscreen-search-input {
    width: 800px;
    background-color: transparent;
    -webkit-box-shadow: 0 2px 0 0 rgba(28, 34, 96, .1);
    -moz-box-shadow: 0 2px 0 0 rgba(28, 34, 96, .1);
    box-shadow: 0 2px 0 0 rgba(28, 34, 96, .1);
    border: 0;
    font-size: 35px;
    padding: 20px;
    color: #1c2260;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.fullscreen-search-overlay #fullscreen-search-wrapper #fullscreen-searchform #fullscreen-search-input:focus {
    boder: none;
    outline: none;
}

.fullscreen-search-overlay.fullscreen-search-overlay-show {
    visibility: visible;
    opacity: 1;
}

.fullscreen-search-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    /*background-color: rgba(255, 255, 255, .94);*/
    background-color: rgb(255, 255, 255);
    text-align: center;
    -webkit-transition: all ease-in-out .25s;
    -moz-transition: all ease-in-out .25s;
    -ms-transition: all ease-in-out .25s;
    -o-transition: all ease-in-out .25s;
    transition: all ease-in-out .25s;
}

.animate-element,
.effect-bg-layer,
.fullscreen-search-overlay {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.fullscreen-search-overlay {
    z-index: 999;
}

.fullscreen-search-overlay .fullscreen-close:link,
.fullscreen-search-overlay .fullscreen-close:visited {
    color: #1c2260;
}

.fullscreen-search-overlay .fullscreen-close {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 26px;
    -webkit-transition: transform ease-out .2s;
    -moz-transition: transform ease-out .2s;
    -ms-transition: transform ease-out .2s;
    -o-transition: transform ease-out .2s;
    transition: transform ease-out .2s;
    transform: rotate(0deg);
}

.fullscreen-search-overlay.fullscreen-search-overlay-show #fullscreen-search-wrapper {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    ms-transform: scale(1);
}

.fullscreen-search-overlay #fullscreen-search-wrapper {
    display: inline-block;
    max-width: 1000px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    ms-transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease-in-out .3s;
    -moz-transition: all ease-in-out .3s;
    -ms-transition: all ease-in-out .3s;
    -o-transition: all ease-in-out .3s;
    transition: all ease-in-out .3s;
}

.search-trigger {
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
}


/* Search Button */


#search-button i {
    color: #fff !important;
}

#search-button:hover,
.fullscreen-close:hover {
    opacity: 0.6;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
}

#fullscreen-search-input:focus {
    box-shadow: 0px 3px 0px 0px rgba(0, 86, 210, 0.54) !important;
}


/* Material Ripple effect */

.material-ripple {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.material-ink {
    position: absolute;
    background: #bdc3c7;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0.4;
}

.blue-ripple > .material-ink {
    background-color: #5c6ac4;
}


/* animation effect */

.material-ink.animate {
    animation: ripple 0.6s linear;
}

@keyframes ripple {
    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}

.control-sidebar-bg, .side-bar {
    right: -240px;
    width: 240px;
    overflow-y: auto;
    height: 100%;
    background-color: #2c3136;
    transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
}

.side-bar {
    display: block;
    position: fixed;
    z-index: 11;
}

.side-bar.right-bar {
    right: -240px;
    /*top: 70px;*/
}

#wrapper.toggled .control-sidebar-bg {
    box-shadow: 0 0 70px 10px rgba(57,55,73,.5);
}
#wrapper.toggled .control-sidebar-bg,
#wrapper.toggled .right-bar {
    right: 0;
}

.control-sidebar-bg {
    top: 0;
}

.control-sidebar-bg {
    position: fixed;
    z-index: 10;
    bottom: 0;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/*Right Sidebar tab*/

.right-sidebar-tabs {
    width: 100%;
}

.right-sidebar-tabs.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    width: 33.33%;
    text-align: center;
}

.right-sidebar-tabs.nav-tabs > li > a {
    color: #c0c0c0;
    font-weight: 600;
    background-color: #1b1f23;
    border-radius: 0;
    font-size: 18px;
    border: 0;
    padding: 8px 15px;
    margin: 0;
}

.right-sidebar-tabs.nav-tabs > li.active > a, 
.right-sidebar-tabs.nav-tabs > li.active > a:focus, 
.right-sidebar-tabs.nav-tabs > li.active > a:hover {
    border: 0;
    background-color: #2c3136;
    color: #fff;
}
.side-bar .tab-content {
    padding: 15px;
}

#styleOptions {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#styleOptions li:nth-child(1),
#styleOptions li:nth-child(5),
#styleOptions li:nth-child(9) {
    display: block;
}

#styleOptions li {
    color: #fff;
    padding: 5px 0;
    list-style: none;
    display: inline-block;
}

#styleOptions li a {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 5px;
    color: #000;
    position: relative;
    box-shadow: 0 0 15px rgba(0,0,0,.4);
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,.4);
}